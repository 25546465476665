<!-- 酒店管理-订单管理-酒店订单详情 -->

<template>
  <div class="hotel-order-detail">
    <w-dialog ref="detailDialogRef" title="酒店订单详情" width="60%" btnWidth="140px" top="10vh" :hideFooter="true"
      class="order-detail">
      <!-- <div class="print-btn" @click="handlePrint">
        <img src="@/assets/img/printer.png" />
        <div>打印</div>
      </div>

      <el-descriptions :column="3" class="basic-info" border>
        <el-descriptions-item v-for="(el, index) in basicInfo" :key="index" :label="el.label">
          <span :style="{
              color: el.color ? el.color[el.value] : '',
            }">
            {{ el.text ? el.text[el.value] : el.value }}</span>
        </el-descriptions-item>
      </el-descriptions> -->
      <div class="basic-infor">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">套餐名称</div>
              <div class="basic-infor-content">{{ detailInfo.hotel_package }}×{{ detailInfo.buy_num }}间</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">所属房型</div>
              <div class="basic-infor-content">{{ detailInfo.hotel_package_type }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">所属酒店</div>
              <div class="basic-infor-content">{{ detailInfo.hotel_name }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">订单号码</div>
              <div class="basic-infor-content">{{ detailInfo.sn }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">购买渠道</div>
              <div class="basic-infor-content">{{ detailInfo.source_text }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付方式</div>
              <div class="basic-infor-content">{{ detailInfo.payway_name }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">下单时间</div>
              <div class="basic-infor-content">{{ detailInfo.ctime }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付金额</div>
              <div class="basic-infor-content">{{ detailInfo.pay_money }}元</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">退款金额</div>
              <div class="basic-infor-content">{{ detailInfo.refund_money }}元</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付时间</div>
              <div class="basic-infor-content">{{ detailInfo.pay_time }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">支付状态</div>
              <div class="status-text" :class="orderStatusColors(detailInfo.pay_status, 'pay')">
                {{ detailInfo.pay_status_text }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">订单状态</div>
              <div class="status-text" :class="orderStatusColors(detailInfo.work_status, 'work')">
                {{ workStatusOptions[detailInfo.work_status] }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">入住日期</div>
              <div class="basic-infor-content">{{ detailInfo.sdate }}至{{ detailInfo.edate }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="basic-infor-title">确认时间</div>
              <div class="basic-infor-content">{{ detailInfo.confirm_time }}({{ detailInfo.confirm_user }})</div>
            </div>
          </el-col>
        </el-row>
      </div>

      <w-title :title_name="'游客信息'"></w-title>
      <common-table ref="peopleTableRef" :ischeck="false" :ispaging="false" :tableData="tableData"
        :columns="peopleTableColumns" @openCheckInDialog="openCheckInDialog">
        <template #status="{ row }">
          <div class="status-text" :class="row.status == '1' ? 'btn-red' : 'btn-blue'">
            {{ row.status == '1' ? '未入住' : '已入住' }}</div>
        </template>

        <template #checkIn="{ row }">
          <template v-if="
              row.status == 1 &&
              authData.indexOf('h_jbjfIOF3RRYWgxHZSNcw7gDm9pML') != -1 &&
              detailInfo?.pay_status == 2 &&
              [3, 6].includes(detailInfo?.work_status)
            ">
            <el-button @click="openCheckInDialog(row)">入住</el-button>
          </template>
        </template>
      </common-table>
      <br />
      <w-title :title_name="'退款明细'"></w-title>
      <common-table ref="refundTableRef" :ischeck="false" :ispaging="false" :tableData="refundData"
        :columns="refundTableColumns">
      </common-table>
    </w-dialog>
    <confirm-dialog ref="checkInDialog" @wConfirm="handleCheckIn" title="确认为该游客办理入住吗？"></confirm-dialog>

    <PrintHotelDialog ref="printHotelRef"></PrintHotelDialog>
  </div>
</template>

<script>
  import { ref, computed, watch, nextTick } from "vue";
  import { HotelApi, } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";
  import ConfirmDialog from "@/components/confirm-dialog/confirm-dialog.vue";
  import PrintHotelDialog from "../../order/components/PrintHotelDialog.vue";
  import {
    getHotelWorkStatusOptions,
    orderStatusColors,
  } from "@/utils/common.js";

  export default {
    emits: ["submit"],
    components: { ConfirmDialog, PrintHotelDialog },
    setup() {
      const detailDialogRef = ref(null);
      const basicInfo = ref({});
      const detailInfo = ref({});
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);
      const authData = ref([]);
      const refundData = ref([]);
      const authInfo = ref({});
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      const printHotelRef = ref(null);
      function handlePrint() {
        closeDialog();
        printHotelRef.value.openDialog(
          basicInfo.value,
          tableData.value,
          refundData.value
        );
      }
      const tableData = ref([]);
      const peopleTableColumns = ref([
        {
          prop: "name",
          label: "游客名",
        },
        {
          prop: "mobile",
          label: "手机号",
        },
        {
          prop: "idcard",
          label: "身份证",
        },
        {
          prop: "status",
          label: "入住状态",
          type: "customRender",
        },
        {
          type: "operation",
          prop: "",
          label: "操作",
          minWidth: 120,
          bottons: [
            {
              type: "customRender",
              action: "checkIn",
            },
          ],
        },
      ]);

      const workStatusOptions = ref(getHotelWorkStatusOptions());
      function getDetail(type) {
        HotelApi.getHotelOrderDetails({ sn: snId.value }).then((res) => {
          if (res.Code === 200) {
            tableData.value = res.Data.reservation;
            refundData.value = res.Data.refund || [];
            detailInfo.value = res.Data;
            basicInfo.value = [
              {
                label: "套餐名称",
                value: detailInfo.value.hotel_package
                  ? detailInfo.value.hotel_package +
                  "×" +
                  detailInfo.value.buy_num +
                  "间"
                  : "-",
              },

              {
                label: "所属房型",
                value: detailInfo.value.hotel_package_type
                  ? detailInfo.value.hotel_package_type
                  : "-",
              },

              {
                label: "所属酒店",
                value: detailInfo.value.hotel_name
                  ? detailInfo.value.hotel_name
                  : "-",
              },
              {
                label: "订单号码",
                value: detailInfo.value.sn ? detailInfo.value.sn : "-",
              },
              {
                label: "购买渠道",
                value: detailInfo.value.source_text
                  ? detailInfo.value.source_text
                  : "-",
              },
              {
                label: "支付方式",
                value: detailInfo.value.payway_name
                  ? detailInfo.value.payway_name
                  : "-",
              },
              {
                label: "下单时间",
                value: detailInfo.value.ctime ? detailInfo.value.ctime : "-",
              },
              {
                label: "支付金额",
                value: detailInfo.value.pay_money
                  ? detailInfo.value.pay_money + "元"
                  : "-",
              },
              {
                label: "退款金额",
                value: detailInfo.value.refund_money
                  ? detailInfo.value.refund_money + "元"
                  : "-",
              },

              {
                label: "支付时间",
                value: detailInfo.value.pay_time
                  ? detailInfo.value.pay_time
                  : "-",
              },

              {
                label: "支付状态",
                value: detailInfo.value.pay_status
                  ? detailInfo.value.pay_status
                  : "-",
                color: allStatusData.value?.payStatus?.colors,
                text: allStatusData.value?.payStatus?.options,
              },
              {
                label: "订单状态",
                value: detailInfo.value.work_status
                  ? detailInfo.value.work_status
                  : "-",
                color: allStatusData.value?.workStatus?.colors,
                text: allStatusData.value?.workStatus?.options,
              },
              {
                label: "入住日期",
                value: detailInfo.value.sdate
                  ? detailInfo.value.sdate + "至" + detailInfo.value.edate
                  : "-",
              },

              {
                label: "确认时间",
                value: detailInfo.value.confirm_time
                  ? detailInfo.value.confirm_user
                    ? detailInfo.value.confirm_time +
                    "(" +
                    detailInfo.value.confirm_user +
                    ")"
                    : detailInfo.value.confirm_time
                  : "-",
              },
            ];
            type == 1 ? detailDialogRef.value.show() : "";
            nextTick(() => {
              if (tableData.value && tableData.value.length != 0) {
                peopleTableRef.value.tableLoad();
              }
              if (refundData.value.length != 0) {
                refundTableRef.value.tableLoad();
              }
            });
          } else {
            let msg = res.Message ? res.Message : "获取详情数据失败！";
            ElMessage.error(msg);
          }
        });
      }
      const snId = ref(null);
      const peopleTableRef = ref(null);
      const refundTableRef = ref(null);
      const refundTableColumns = ref([
        {
          prop: "refund_sn",
          label: "退款单号",
          minWidth: 140,
        },
        {
          prop: "refund_money",
          label: "退款金额",
        },
        {
          prop: "refund_payway_name",
          label: "退款方式",
        },
        {
          prop: "refund_confirm_user",
          label: "退款确认人",
        },
        {
          prop: "refund_confirm_time",
          label: "退款时间",
        },
        {
          prop: "refund_remark",
          label: "退款原因",
        },
      ]);

      const allStatusData = ref({});
      function openDialog(id, data) {
        allStatusData.value = data;
        snId.value = id;
        getDetail(1);
      }
      function closeDialog() {
        detailDialogRef.value.close();
      }
      const checkInDialog = ref(null);
      const currentCheckIn = ref({});
      function openCheckInDialog(row) {
        currentCheckIn.value = {
          ohr_id: row.id,
          sn: detailInfo.value.sn,
        };
        checkInDialog.value.show();
      }
      function handleCheckIn() {
        checkInDialog.value.isLoading = true;
        HotelApi.checkinHotelOrder(currentCheckIn.value).then((res) => {
          checkInDialog.value.isLoading = false;
          if (res.Code === 200) {
            checkInDialog.value.close();
            getDetail(2);
            ElMessage.success("操作成功");
            emit('submit');
          } else {
            let msg = res.Message ? res.Message : "操作失败！";
            ElMessage.error(msg);
          }
        });
      }
      return {
        closeDialog,
        basicInfo,
        detailDialogRef,
        openDialog,
        detailInfo,
        authInfo,
        authData,
        peopleTableRef,
        tableData,
        peopleTableColumns,
        openCheckInDialog,
        handleCheckIn,
        checkInDialog,
        refundData,
        refundTableRef,
        refundTableColumns,
        handlePrint,
        printHotelRef,
        orderStatusColors,
        workStatusOptions,
      };
    },
  };
</script>

<style lang="scss">
  .hotel-order-detail {
    .basic-infor {
      background-color: #F3F4F8;
      border-radius: 8px;
      padding: 15px;
      margin-bottom: 20px;
    }

    .basic-infor-title {
      color: #92979E;
      width: 80px;
      text-align: left;
      margin-bottom: 10px;
      flex-shrink: 0;
    }

    .basic-infor-content {
      color: #262C30;
    }

    .print-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      img {
        width: 20px;
        height: 20px;
      }

      div {
        width: 40px;
        padding: 10px 0;
        text-align: center;
        color: var(--theme-color);
      }
    }

    .order-detail {
      .el-dialog__body {
        padding-bottom: 200px;
      }

      .basic-info {
        border-radius: 4px;
        margin-bottom: 30px;
        padding: 15px;
        background-color: var(--search-uncheck-bg-color);

        .el-descriptions__body {
          border: none;
          background-color: var(--search-uncheck-bg-color);

          .el-descriptions__label,
          .el-descriptions__content {
            border: 1px solid transparent;
            background-color: var(--search-uncheck-bg-color);
          }

          .el-descriptions__label {
            width: 64px;
            color: var(--text-gray-color);
            font-weight: normal;
          }

          .el-descriptions__cell {
            padding: 0 0 4px 0;
          }

          .el-descriptions__content {
            color: var(--text-color);
            min-width: 100px;
          }
        }
      }

      .refund-desc {
        >span {
          color: var(--text-third-color);
        }

        .info {
          background-color: var(--search-uncheck-bg-color);
          padding: 16px 30px;
          border-radius: 4px;
          color: var(--text-gray-color);
        }
      }
    }
  }
</style>